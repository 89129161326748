export const workOrderStatusOptions = [
    {
        value: 0,
        label: '待回复',
        type: 'warning'
    },
    {
        value: 1,
        label: '已回复',
        type: 'primary'
    },
    {
        value: 2,
        label: '已完成',
        type: 'success'
    }
]

export const workOrderTypeOptions = [
    {
        value: 1,
        label: '客服事件',
        type: 'primary'
    },
    {
        value: 2,
        label: '判罚事件',
        type: 'danger'
    }
]


export const workOrderEventTypeOptions = [
    {"label": "下单预订问题", "value": 15821},
    {"label": "订单费用支付问题", "value": 15835},
    {"label": "优惠券使用问题", "value": 15856},
    {"label": "行李人数问题", "value": 15870},
    {"label": "订单取消修改问题", "value": 15905},
    {"label": "订单派车问题", "value": 15919},
    {"label": "司乘联系问题", "value": 15954},
    {"label": "订单退赔款问题", "value": 15947},
    {"label": "定位或导航问题", "value": 15968},
    {"label": "行驶路线问题", "value": 15982},
    {"label": "发票和报销凭证问题", "value": 15996},
    {"label": "司机服务质量问题", "value": 16010},
    {"label": "客服服务质量问题", "value": 16024},
    {"label": "其他业务咨询", "value": 16038},
    {"label": "加盟问题", "value": 16052},
    {"label": "账户信息问题", "value": 16066},
    {"label": "司机分和补贴申请问题", "value": 16080},
    {"label": "判罚申诉问题", "value": 16094}
]

export const appealStatusOptions = [
    {
        label: '待申诉',
        value: 'appealing',
        type: 'warning'
    },
    {
        label: '待申诉专员回复',
        value: 'auditing',
        type: 'primary'
    },
    {
        label: '申诉失败',
        value: 'failed',
        type: 'danger'
    },
    {
        label: '申诉成功',
        value: 'passed',
        type: 'success'
    }
]

export const documentStatusOptions = [
    {
        label: '可申诉',
        value: 1,
        type: 'primary'
    },
    {
        label: '不可申诉',
        value: 0,
        type: 'info'
    }
]

export const punishObjectTypeOptions = [
    {
        label: '司机',
        value: 1
    },
    {
        label: '供应商',
        value: 2
    },
    {
        label: '司机和供应商',
        value: 3
    }
]

export const punishOrderStatusOptions = [
    {
        label: '待执行',
        value: 0,
        type: 'warning'
    },
    {
        label: '已执行',
        value: 1,
        type: 'danger'
    },
    {
        label: '已取消',
        value: 2,
        type: 'info'
    }
]

export const punishWayOptions = [
    {
        label: '罚款',
        value: 1
    },
    {
        label: '下线',
        value: 2
    },
    {
        label: '冻结',
        value: 3
    }
]
export const reasonForViolations = [
    {
        "secondFoulReasonName": "肢体冲突",
        "secondFoulReasonCode": 100067,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "泄露乘客信息",
        "secondFoulReasonCode": 100068,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未与乘客确认路线、未开启导航、道路不熟",
        "secondFoulReasonCode": 100037,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未提供X产品服务",
        "secondFoulReasonCode": 100043,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未送达目的地",
        "secondFoulReasonCode": 100049,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未按规则联系乘客",
        "secondFoulReasonCode": 100036,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未按规定收取乘客费用",
        "secondFoulReasonCode": 100053,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未按规定开票",
        "secondFoulReasonCode": 800829,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未按规定接引乘客（未提拿行李等）",
        "secondFoulReasonCode": 800828,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "未按规定接引乘客（等待位置偏差、未提拿行李等）",
        "secondFoulReasonCode": 100035,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "危险驾驶",
        "secondFoulReasonCode": 100065,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "拖延服务时长",
        "secondFoulReasonCode": 100236,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "私下骚扰/恶意辱骂/欺骗乘客",
        "secondFoulReasonCode": 100066,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "司机诱导乘客取消订单",
        "secondFoulReasonCode": 100046,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "司机引导客户消费/推销购物",
        "secondFoulReasonCode": 100052,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "司机仪容仪表不合规，有异味等",
        "secondFoulReasonCode": 100038,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "司机或供应商原因导致未用车",
        "secondFoulReasonCode": 100057,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "司机飞单",
        "secondFoulReasonCode": 100056,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "人车不符",
        "secondFoulReasonCode": 100064,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "绕路",
        "secondFoulReasonCode": 100233,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "强制要好评",
        "secondFoulReasonCode": 800726,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "其他违法违规行为",
        "secondFoulReasonCode": 100069,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-未按规定收取乘客费用",
        "secondFoulReasonCode": 100225,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-未按规定开票",
        "secondFoulReasonCode": 800832,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-拖延服务时长",
        "secondFoulReasonCode": 100235,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-司机或供应商原因导致未用车（接送站）",
        "secondFoulReasonCode": 800724,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-司机或供应商原因导致未用车",
        "secondFoulReasonCode": 100229,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-绕路",
        "secondFoulReasonCode": 100234,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-车上有人",
        "secondFoulReasonCode": 100226,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "聚合OTA-不安全驾驶（非违反交通法行为）",
        "secondFoulReasonCode": 100230,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "仅特惠",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "拒绝归还乘客财物",
        "secondFoulReasonCode": 100060,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "减少用车里程数/用车时间",
        "secondFoulReasonCode": 100198,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "服务态度问题",
        "secondFoulReasonCode": 100044,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "服务商/司机无法服务",
        "secondFoulReasonCode": 800664,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "打扰乘客（轻微骚扰、播放音乐等）",
        "secondFoulReasonCode": 100039,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "迟到（未造成到场无车）",
        "secondFoulReasonCode": 100047,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "车上有人",
        "secondFoulReasonCode": 100048,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "车内外不整洁、车内温度不适宜",
        "secondFoulReasonCode": 100040,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "车辆信息推送不及时不准确（非人车不符）",
        "secondFoulReasonCode": 100045,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "车辆无运营资质",
        "secondFoulReasonCode": 100063,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "车辆配置不达标",
        "secondFoulReasonCode": 100051,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "车辆备品不全/硬件损坏",
        "secondFoulReasonCode": 100041,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "不按规范操作-未佩戴口罩",
        "secondFoulReasonCode": 100145,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "不按规范操作-未及时清理后备箱行李放不下",
        "secondFoulReasonCode": 100146,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "不按规范操作-未按照服务标准执行服务",
        "secondFoulReasonCode": 100147,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "不按规范操作-其他",
        "secondFoulReasonCode": 100050,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "不安全驾驶（非违反交通法行为）",
        "secondFoulReasonCode": 100058,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境内"
    },
    {
        "secondFoulReasonName": "行程有变，暂不需要用车",
        "secondFoulReasonCode": 100018,
        "firstfoulReasonName": "用户原因退款",
        "firstfoulReasonCode": 12,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "信息输入错误",
        "secondFoulReasonCode": 100019,
        "firstfoulReasonName": "用户原因退款",
        "firstfoulReasonCode": 12,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "服务商未确认，担心影响行程",
        "secondFoulReasonCode": 100020,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "司机信息未更新，担心影响行程",
        "secondFoulReasonCode": 100021,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "联系不上司机",
        "secondFoulReasonCode": 100022,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "不是订单显示车辆或司机",
        "secondFoulReasonCode": 100023,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "司机无法准时赶到",
        "secondFoulReasonCode": 100024,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "司机要额外收取其他费用",
        "secondFoulReasonCode": 100025,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "航班延误/取消/备降等",
        "secondFoulReasonCode": 100026,
        "firstfoulReasonName": "不可抗力退款",
        "firstfoulReasonCode": 14,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "突发事件（身故/住院/骨折/怀孕/疫情/自然灾害）",
        "secondFoulReasonCode": 100027,
        "firstfoulReasonName": "不可抗力退款",
        "firstfoulReasonCode": 14,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "行程规划不合理",
        "secondFoulReasonCode": 100028,
        "firstfoulReasonName": "平台原因退款",
        "firstfoulReasonCode": 15,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "系统存在故障",
        "secondFoulReasonCode": 100029,
        "firstfoulReasonName": "平台原因退款",
        "firstfoulReasonCode": 15,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "其他平台价格更低",
        "secondFoulReasonCode": 100030,
        "firstfoulReasonName": "其他原因退款",
        "firstfoulReasonCode": 16,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "我不接受服务商/车型调整",
        "secondFoulReasonCode": 100031,
        "firstfoulReasonName": "其他原因退款",
        "firstfoulReasonCode": 16,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "特殊要求无法满足",
        "secondFoulReasonCode": 100032,
        "firstfoulReasonName": "其他原因退款",
        "firstfoulReasonCode": 16,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "内部测试",
        "secondFoulReasonCode": 100033,
        "firstfoulReasonName": "其他原因退款",
        "firstfoulReasonCode": 16,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "其他",
        "secondFoulReasonCode": 100034,
        "firstfoulReasonName": "其他原因退款",
        "firstfoulReasonCode": 16,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "服务商或司机无法服务",
        "secondFoulReasonCode": 800643,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "司机要我取消",
        "secondFoulReasonCode": 800644,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "未更新司机点位",
        "secondFoulReasonCode": 800773,
        "firstfoulReasonName": "司机/服务商原因退款",
        "firstfoulReasonCode": 13,
        "productType": "接送机站、包车",
        "type": "境内+境外"
    },
    {
        "secondFoulReasonName": "境外-未按规定接引乘客（等待位置偏差、未提拿行李等）",
        "secondFoulReasonCode": 800852,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-未按规则联系乘客",
        "secondFoulReasonCode": 800853,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-未与乘客确认路线、未开启导航、道路不熟",
        "secondFoulReasonCode": 800854,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-不按规范操作-其他",
        "secondFoulReasonCode": 800855,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-不按规范操作-未按照服务标准执行服务",
        "secondFoulReasonCode": 800858,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-服务态度问题",
        "secondFoulReasonCode": 800859,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-司机仪容仪表不合规，有异味等",
        "secondFoulReasonCode": 800860,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-车内外不整洁、车内温度不适宜",
        "secondFoulReasonCode": 800862,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-车辆备品不全/硬件损坏",
        "secondFoulReasonCode": 800863,
        "firstfoulReasonName": "一级违规",
        "firstfoulReasonCode": 1,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-未提供X产品服务",
        "secondFoulReasonCode": 800864,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-司机诱导乘客取消订单",
        "secondFoulReasonCode": 800866,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-迟到（未造成到场无车）",
        "secondFoulReasonCode": 800867,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-未送达目的地",
        "secondFoulReasonCode": 800868,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-车辆信息推送不及时不准确（非人车不符）",
        "secondFoulReasonCode": 800869,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-强制要好评",
        "secondFoulReasonCode": 800870,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-车辆配置不达标",
        "secondFoulReasonCode": 800871,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-线下引导客户消费/推销购物 ",
        "secondFoulReasonCode": 800872,
        "firstfoulReasonName": "二级违规",
        "firstfoulReasonCode": 2,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-绕路",
        "secondFoulReasonCode": 800873,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-未按规定收取乘客费用",
        "secondFoulReasonCode": 800874,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-拖延服务时长",
        "secondFoulReasonCode": 800875,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-车上有人",
        "secondFoulReasonCode": 800876,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-不安全驾驶（非违反交通法行为）",
        "secondFoulReasonCode": 800879,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-司机或供应商原因导致未用车",
        "secondFoulReasonCode": 800880,
        "firstfoulReasonName": "三级违规",
        "firstfoulReasonCode": 3,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-拒绝归还乘客财物",
        "secondFoulReasonCode": 800882,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-司机飞单",
        "secondFoulReasonCode": 800884,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-司机或车辆信息虚假、车辆无运营资质",
        "secondFoulReasonCode": 800886,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-人车不符",
        "secondFoulReasonCode": 800887,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-危险驾驶",
        "secondFoulReasonCode": 800888,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-私下骚扰/恶意辱骂/欺骗乘客",
        "secondFoulReasonCode": 800889,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-肢体冲突",
        "secondFoulReasonCode": 800890,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    },
    {
        "secondFoulReasonName": "境外-其他违法违规行为",
        "secondFoulReasonCode": 800892,
        "firstfoulReasonName": "四级违规",
        "firstfoulReasonCode": 4,
        "productType": "接送机站、包车",
        "type": "境外"
    }
]
