import http from '@/common/js/http'

/**
 * 列表
 */
export const getWorkOrderList = async (data) => {
    return http('/galaxyOrder/bmsOrderEventList', data, 'POST')
}
/**
 * 创建
 */
export const createOrderEventApi = async (data) => {
    return http('/galaxyOrder/bmsCreateOrderEvent', data, 'POST')
}
/**
 * 回复
 */
export const replyOrderEventApi = async (data) => {
    return http('/galaxyOrder/replyOrderEvent', data, 'POST')
}

/**
 * 惩罚事件详情
 */
export const getOrderPunishDetailApi = async (data) => {
    return http('/galaxyOrder/orderPunishDetail', data, 'POST')
}

/**
 * 惩罚事件列表
 */
export const getOrderPunishListApi = async (data) => {
    return http('/galaxyOrder/orderPunishList', data, 'POST')
}
