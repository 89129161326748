<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <com-list-search>
                <el-form :inline="true" size="small" slot="list-search-before">
                    <el-form-item>
                        <el-input placeholder="单据ID" style="width: 240px" clearable
                                  v-model="form.ctripEventId"/>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect placeholder="单据状态" :options="documentStatusOptions" clearable
                                   v-model="form.appealable"/>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect placeholder="申诉状态" :options="appealStatusOptions" clearable
                                   v-model="form.appealStatus"/>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect placeholder="判罚对象" :options="punishObjectTypeOptions" clearable
                                   v-model="form.punishObjectType"/>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect placeholder="违规原因" :options="reasonForViolations" filterable clearable multiple
                                   v-model="form.secondFoulReasonCodeList"/>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            v-model="form.createTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="创建开始日期"
                            end-placeholder="创建结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div slot="list-search-after" style="width: 170px;display: flex;justify-content: flex-end">
                    <el-button @click="resetClick()">重置</el-button>
                    <el-button type="primary" @click="getList()" :loading="loading">搜索</el-button>
                </div>
            </com-list-search>
            <SectionContent>
                <div v-loading="loading">
                    <el-table :data="result.data" size="small">
                        <el-table-column label="单据ID/状态" width="120">
                            <template slot-scope="scope">
                                <p> {{ scope.row.ctripEventId }}</p>
                                <p class="mt5">
                                    <el-tag size="mini"
                                            effect="plain"
                                            :type="scope.row.punishOrderStatus | formatDesc(punishOrderStatusOptions,'value','type')">
                                        {{ scope.row.punishOrderStatus | formatDesc(punishOrderStatusOptions) }}
                                    </el-tag>
                                </p>
                                <p class="mt5">
                                    <el-tag size="mini"
                                            effect="plain"
                                            :type="scope.row.appealStatus | formatDesc(appealStatusOptions,'value','type')">
                                        {{ scope.row.appealStatus | formatDesc(appealStatusOptions) }}
                                    </el-tag>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="违规原因">
                            <template slot-scope="scope">
                                {{ scope.row.firstfoulReasonName }} - {{ scope.row.secondFoulReasonName }}
                            </template>
                        </el-table-column>
                        <el-table-column label="惩罚方式/力度" width="160">
                            <template slot-scope="scope" v-if="scope.row.punishContentDetail">
                                <p v-for="(item,index) in scope.row.punishContentDetail" :key="index">
                                    {{
                                        item.punishWay | formatDesc(punishWayOptions)
                                    }}/{{ item.punishWeight }}{{ item.punishWeightUnit }}
                                </p>
                            </template>
                        </el-table-column>
<!--                        <el-table-column label="违规备注" width="120">-->
<!--                            <template slot-scope="scope">-->
<!--                                {{ scope.row.tips || '-' }}-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                        <el-table-column label="剩余申诉次数" width="120">
                            <template slot-scope="scope">
                                {{ scope.row.maxAppealTime }}
                            </template>
                        </el-table-column>
                        <el-table-column label="申诉截止时间" prop="deadLine" width="160"></el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-link size="mini" type="primary"
                                         :href="'#/workOrderDetail?id='+scope.row.ctripEventId">
                                    {{
                                        scope.row.appealable === 1 && scope.row.appealStatus === 'appealing' ? '进行申诉' : '详情'
                                    }}
                                </el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
    </div>
</template>

<script>
import {
    workOrderStatusOptions,
    workOrderTypeOptions,
    appealStatusOptions,
    documentStatusOptions,
    punishObjectTypeOptions,
    reasonForViolations,
    punishWayOptions,
    punishOrderStatusOptions
} from '@/data/workOrder'
import comListSearch from "../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import {getOrderPunishListApi} from "@/www/urls/workOrder";
import dayjs from "dayjs";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    ctripEventId: undefined,
    appealStatus: undefined,
    appealable: undefined,
    punishObjectType: undefined,
    secondFoulReasonCodeList: []
})
export default {
    name: "workOrderPenalty",
    data() {
        return {
            appealStatusOptions,
            documentStatusOptions,
            workOrderStatusOptions,
            workOrderTypeOptions,
            punishObjectTypeOptions,
            punishWayOptions,
            punishOrderStatusOptions,
            loading: true,
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    components: {
        comListSearch,
        SectionContent,
    },
    computed: {
        reasonForViolations() {
            return reasonForViolations.map(({secondFoulReasonName, secondFoulReasonCode, firstfoulReasonName}) => ({
                label: `${firstfoulReasonName}-${secondFoulReasonName}`,
                value: secondFoulReasonCode
            }))
        }
    },
    activated() {
        this.getList();
    },
    methods: {
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getOrderPunishListApi({
                ...this.form,
                createTime: undefined,
                createTimeStart: this.form.createTime && this.form.createTime.length ? dayjs(this.form.createTime[0]).startOf('d').valueOf() : undefined,
                createTimeEnd: this.form.createTime && this.form.createTime.length ? dayjs(this.form.createTime[1]).endOf('d').valueOf() : undefined,
                secondFoulReasonCodeList: this.form.secondFoulReasonCodeList.length ? this.form.secondFoulReasonCodeList : undefined
            })
            this.loading = false
            if (ret.success) {
                ret.data.data = ret.data.data.map((item) => ({
                    ...item,
                    eventDetail: item.eventDetail ? JSON.parse(item.eventDetail) : {},
                    punishContentDetail: item.punishContentDetail ? JSON.parse(item.punishContentDetail) : {},
                }))
                this.result = ret.data;
            } else this.$message.error(ret.errors[0].message)

        },
        resetClick() {
            this.form = createForm();
            this.getList(1)
        }
    }
}
</script>

<style scoped lang="scss">
.common-list-search::v-deep {
    .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }
}

.list-item {
    border: 1px solid #f2f4f6;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    background-color: #fff;

    &:hover {
        box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
    }

    .list-item_hd {
        border-bottom: 1px solid #f2f4f6;
        display: flex;
        padding-bottom: 10px;
    }

    .list-item_bd {
        padding-top: 10px;
        display: flex;

        & > div:not(:first-child) {
            flex: 1;
        }

        & > div:first-child {
            width: 50%;
            padding-right: 50px;
        }
    }
}
</style>
